@import "variables";

main {
  padding-top: 173px;
}

.container {
  width: 100%;
  padding: 0 5%;
  margin: 0 auto;
  max-width: 1600px;
}

.btn {
  position: relative;
  padding: 16px 32px;
  display: inline-block;
  text-align: center;
  border: 0;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 4px;
  text-transform: uppercase;

  &-default {
    background: $color-primary;
    color: #ffffff;

    &:hover {
      background: $color-secondary;
    }
  }

  &-outlined {
    background: transparent;
    color: $color-primary;
    border: 1px solid $color-primary;

    &:hover {
      background: $color-primary;
      color: #ffffff;
    }
  }

  &-small {
    font-size: 14px;
    padding: 12px 24px;
  }

  &-large {
    font-size: 20px;
    padding: 20px 50px;
  }

  &-block {
    width: 100%;
    display: block;
    text-align: center;
  }

  &:hover {
    text-decoration: none;
  }
}

.title {
  margin-bottom: 70px;
  text-align: center;
  h2 {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    color: $color-primary;
  }
  &-link {
    display: inline-flex;
    align-items: center;
    color: $text-color-secondary;
    .fas {
      margin-left: 8px;
    }
  }
}

// form
.form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  &-control {
    margin-bottom: 20px;
    flex: 0 0 100%;
    width: 100%;

    &.half {
      flex: 0 0 49%;
      width: 49%;
    }

    input {
      border-radius: 0px;
      height: 44px;
      background: #f2f2f2;
      width: 100%;
      border-radius: 0px;
      font-size: 15px;
      padding: 16px;
      border-radius: 12px;
    }

    textarea {
      border-radius: 0px;
      min-height: 180px;
      background: #f2f2f2;
      width: 100%;
      border-radius: 0px;
      font-size: 15px;
      padding: 16px;
      resize: none;
      border-radius: 12px;
    }

    &__hint {
      font-size: 12px;
      display: block;
      margin-top: 5px;
      color: red;
    }
  }

  &-button {
    text-align: center;
    flex: 0 0 100%;
    width: 100%;
  }
}
// end form

// category
.category {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 1px solid #dadada;

  &-item {
    margin: 0 30px;

    input[type="radio"],
    input[type="check"] {
      display: none;

      &:checked + label {
        font-weight: 700;
        text-decoration: none;
        color: $color-primary;

        &::before {
          opacity: 1;
          height: 4px;
          width: 90%;
        }
      }
    }

    label {
      font-size: 16px;
      position: relative;
      padding: 0 0 20px;
      display: inline-block;

      &::before {
        content: "";
        background: $color-primary;
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 30%;
        height: 0px;
        border-radius: 6px;
        opacity: 0;
        transform: translateX(-50%);
        transition: height 0.15s ease, bottom 0.15s ease, width 0.2s ease-in-out 0.15s, opacity 0.3s ease;
      }

      &:hover {
        text-decoration: none;
        color: $color-primary;

        &::before {
          opacity: 1;
          height: 4px;
          width: 90%;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
// end category

// box-service
.box-service {
  flex: 0 0 31.33%;
  width: 31.33%;
  display: inline-block;
  position: relative;
  min-height: 240px;
  overflow: hidden;

  &__title {
    text-align: center;
    color: $color-primary;
    position: absolute;
    z-index: 2;
    bottom: -40px;
    left: 50%;
    transform: translate(-50%, 0%);
    transition: all 200ms ease-in-out;
    font-size: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 240px;
    transition: all 200ms ease-in-out;
    display: block;
  }

  &::before {
    content: "";
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    transition: all 200ms ease-in-out;
    opacity: 0;
  }

  &:hover {
    text-decoration: none;

    .box-service {
      &__title {
        bottom: 50%;
        opacity: 1;
        transform: translate(-50%, 50%);
      }
    }
    img {
      transform: scale(1.1);
    }
    &::before {
      opacity: 1;
    }
  }
}
// end box-service

// products
.products {
  &-list {
    display: flex;
    flex-flow: row wrap;
    gap: 24px;

    &__button {
      flex: 0 0 100%;
      width: 100%;
      text-align: center;
    }
  }

  &-item {
    flex: 0 0 calc(25% - 18px);
    width: calc(25% - 18px);
    border-radius: 12px;
    border: 1px solid #dadada;
    transition: all 300ms;
    margin-bottom: 40px;
    position: relative;

    &__image {
      padding: 36px 36px 24px;
      position: relative;
      overflow: hidden;
      display: block;

      img {
        display: block;
        height: 240px;
        width: 100%;
        object-fit: contain;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__content {
      text-align: center;
      padding: 0 36px 92px;
    }

    &__title {
      font-size: 18px;
      font-weight: 700;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__price {
      display: block;
      margin: 10px 0 6px;
      font-size: 30px;
      font-weight: 700;
      color: $color-primary;

      small {
        font-size: 14px;
        text-decoration: line-through;
        color: $text-color-secondary;
      }
    }

    &__description {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      line-height: 1.4;
      color: $text-color-secondary;
    }

    .btn {
      position: absolute;
      bottom: 24px;
      left: 36px;
      width: calc(100% - 72px);
    }

    &:hover {
      box-shadow: 0 5px 10px rgba(36, 50, 56, 0.25);
    }
  }

  &-filter {
    background: #f2f2f2;
    padding: 16px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    margin-bottom: 30px;

    span {
      font-size: 15px;
      color: $text-color-secondary;

      b {
        color: $color-primary;
      }
    }

    &__select {
      position: relative;
      label {
        font-size: 14px;
        color: $text-color-secondary;
        margin-right: 5px;
      }

      select {
        height: 44px;
        padding: 0 16px;
        background: transparent;
        border-radius: 0px;
        -webkit-appearance: none;
        appearance: none;
        font-size: 15px;
        border: 1px solid $color-primary;
        outline: 0;
        border-radius: 12px;
      }

      &::before {
        content: "\f0d7";
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        position: absolute;
        right: 16px;
        font-size: 14px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }
  }
}
// products

// blog
.blog {
  &-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &-item {
    width: 49%;
    flex: 0 0 49%;
    display: flex;
    flex-flow: row;
    align-items: center;

    &__image {
      min-height: 300px;
      height: 100%;
      width: 230px;
      flex: 0 0 230px;
      position: relative;
      border-radius: 12px;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        display: block;
        object-fit: cover;
      }
    }

    &__content {
      padding-left: 40px;
      padding-top: 40px;
      padding-bottom: 30px;
    }

    &__title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    &__description {
      font-size: 14px;
      line-height: 1.5;
    }

    .btn {
      margin-top: 50px;
    }
  }
}
// end blog

// social-share
.social-share {
  &__item {
    display: block;
    margin-bottom: 15px;

    .fab,
    .fas {
      line-height: 48px;
      color: #ffffff;
      font-size: 1.25em;
    }
  }
  &__link {
    display: inline-block;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    background: $color-secondary;
    text-align: center;

    &:hover {
      transform: scale(1.1);
    }

    &--facebook {
      background: #475a95;
    }

    &--twitter {
      background: #659fef;
    }

    &--linkedin {
      background: #4b77b2;
    }

    &--mail {
      background: #848484;
    }
  }
}
// end social-share

.filter {
  .ngx-slider {
    .ngx-slider-bar {
      background: #d5d5d5;
      height: 5px;
    }

    .ngx-slider-bubble {
      font-family: "Raleway", sans-serif;
      font-size: 14px;

      &.ngx-slider-model-value,
      &.ngx-slider-model-high {
        font-weight: 700;
        color: $color-primary;
        font-size: 20px;
      }
    }

    .ngx-slider-pointer {
      background-color: $color-primary;
      width: 16px;
      height: 16px;
      top: 50%;
      transform: translateY(-50%);
      outline: 0;

      &.ngx-slider-active {
        &::after {
          background: #ffffff;
        }
      }

      &::after {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .ngx-slider-selection {
      background: $color-primary;
    }
  }
}

@media screen and (max-width: 768px) {
  main {
    padding-top: 54px;
  }

  .title {
    margin-bottom: 40px;
  }

  .form {
    &-control {
      &.half {
        flex: 0 0 100%;
        width: 100%;
      }
    }
  }

  .box-service {
    flex: 0 0 100%;
    width: 100%;
    min-height: 120px;
    margin-bottom: 16px;

    &::before {
      opacity: 1;
    }

    &__title {
      bottom: 50%;
      transform: translate(-50%, 50%);
    }

    img {
      height: 120px;
    }
  }

  .category {
    flex-flow: column;
    align-items: center;

    &-item {
      padding: 10px 0;
    }
  }

  .products {
    &-list {
      justify-content: space-between;
    }

    &-filter {
      flex-flow: column;
      align-items: stretch;

      &__select {
        margin-top: 20px;
        display: flex;
        flex-flow: row;
        align-items: center;

        label {
          flex-grow: 1;
          width: 100%;
        }
      }
    }

    &-item {
      flex: 0 1 100%;
      width: 100%;

      &__image {
        min-height: 160px;

        img {
          height: 160px;
        }
      }

      &:not(:nth-child(4n)) {
        margin-right: 0px;
      }
    }
  }

  .blog {
    &-list {
      flex-flow: column;
    }

    &-item {
      flex: 0 0 100%;
      width: 100%;
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 40px;

      &__image {
        min-height: 160px;
        flex: 0 0 100%;
        width: 100%;
        height: auto;

        img {
          height: 160px;
          width: 100%;
        }
      }

      &__content {
        padding: 16px;

        .btn {
          display: block;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
