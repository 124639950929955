@import "variables";

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
hr,
th,
td {
  margin: 0;
  padding: 0;
}
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
h1,
h2 {
  font-weight: 700;
}
small {
  font-weight: 400;
  font-size: 12px;
}
html {
  -webkit-font-smoothing: antialiased;
}
html {
  font-size: 100%;
  font-family: "Montserrat", sans-serif;
}
strong,
b {
  font-weight: 700;
}
p {
  color: currentColor;
  font-size: 15px;
  line-height: 24px;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
  color: currentColor;
}
a:hover {
  text-decoration: underline;
}
a,
button {
  transition: all 0.2s;
  font: inherit;
}
a:hover,
button:hover {
  cursor: pointer;
}
input,
textarea,
button {
  outline: none;
  border: 0;
  background: transparent;
}
body {
  color: $text-color-primary;
  background: #ffffff;
  letter-spacing: 0px;
}
.clear {
  width: 100%;
  clear: both;
  display: block;
  height: 0px;
}
img {
  max-width: 100%;
  height: auto;
  word-break: break-all;
}
input,
textarea,
select {
  color: inherit;
  font-family: inherit;

  &::placeholder {
    color: currentColor;
    opacity: 0.85;
  }
}
table {
  width: 100%;
}
::selection {
  background: #ccc;
  color: #fff;
}
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.hidden-desktop {
  display: none;
}

.summernote {
  a {
    color: $color-secondary;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  iframe {
    min-height: 460px;
    width: 100%;
  }

  p {
    width: 100% !important;
  }

  ul,
  p,
  iframe,
  img ol,
  li,
  u,
  b,
  s {
    max-width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
  }

  img {
    border-radius: 6px;
  }

  table {
    width: 100% !important;
    margin: 0 auto !important;
    max-width: 700px !important;

    tr {
      background: transparent !important;
    }

    td,
    th {
      text-align: left;
      width: auto !important;
      border-bottom: 1px solid #ccc !important;
      font-style: normal !important;
      font-size: 16px !important;
      font-weight: 500;
      padding: 20px 16px !important;
      line-height: 1 !important;

      &:first-child {
        color: $text-color-primary !important;
      }

      &:last-child {
        color: $text-color-secondary !important;
        text-align: right;
      }
    }
  }
}

.ngxImageZoomContainer {
  border-radius: 12px;
  width: 100% !important;
  height: 100% !important;
  position: relative;
  overflow: hidden;
  border: 1px solid #cccccc;

  &:hover {
    cursor: zoom-in;
  }

  img {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 768px) {
  .hidden-desktop {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .hidden-mobile {
    display: none !important;
  }

  .summernote {
    .content-image {
      width: 100% !important;
    }
    img,
    ul,
    p,
    iframe,
    img ol,
    li,
    u,
    b,
    s,
    div {
      height: auto !important;
      width: 100% !important;
      position: relative !important;
    }
  }

  .ngxImageZoomContainer {
    width: 100% !important;
    height: auto !important;
  }
}
