body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
hr,
th,
td {
  margin: 0;
  padding: 0;
}

h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1,
h2 {
  font-weight: 700;
}

small {
  font-weight: 400;
  font-size: 12px;
}

html {
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 100%;
  font-family: "Montserrat", sans-serif;
}

strong,
b {
  font-weight: 700;
}

p {
  color: currentColor;
  font-size: 15px;
  line-height: 24px;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

a:hover {
  text-decoration: underline;
}

a,
button {
  transition: all 0.2s;
  font: inherit;
}

a:hover,
button:hover {
  cursor: pointer;
}

input,
textarea,
button {
  outline: none;
  border: 0;
  background: transparent;
}

body {
  color: #323232;
  background: #ffffff;
  letter-spacing: 0px;
}

.clear {
  width: 100%;
  clear: both;
  display: block;
  height: 0px;
}

img {
  max-width: 100%;
  height: auto;
  word-break: break-all;
}

input,
textarea,
select {
  color: inherit;
  font-family: inherit;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: currentColor;
  opacity: 0.85;
}

table {
  width: 100%;
}

::selection {
  background: #ccc;
  color: #fff;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

.hidden-desktop {
  display: none;
}

.summernote a {
  color: #94c13e;
}
.summernote::after {
  content: "";
  display: table;
  clear: both;
}
.summernote iframe {
  min-height: 460px;
  width: 100%;
}
.summernote p {
  width: 100% !important;
}
.summernote ul,
.summernote p,
.summernote iframe,
.summernote img ol,
.summernote li,
.summernote u,
.summernote b,
.summernote s {
  max-width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
}
.summernote img {
  border-radius: 6px;
}
.summernote table {
  width: 100% !important;
  margin: 0 auto !important;
  max-width: 700px !important;
}
.summernote table tr {
  background: transparent !important;
}
.summernote table td,
.summernote table th {
  text-align: left;
  width: auto !important;
  border-bottom: 1px solid #ccc !important;
  font-style: normal !important;
  font-size: 16px !important;
  font-weight: 500;
  padding: 20px 16px !important;
  line-height: 1 !important;
}
.summernote table td:first-child,
.summernote table th:first-child {
  color: #323232 !important;
}
.summernote table td:last-child,
.summernote table th:last-child {
  color: #707070 !important;
  text-align: right;
}

.ngxImageZoomContainer {
  border-radius: 12px;
  width: 100% !important;
  height: 100% !important;
  position: relative;
  overflow: hidden;
  border: 1px solid #cccccc;
}
.ngxImageZoomContainer:hover {
  cursor: zoom-in;
}
.ngxImageZoomContainer img {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .hidden-mobile {
    display: none !important;
  }
  .summernote .content-image {
    width: 100% !important;
  }
  .summernote img,
.summernote ul,
.summernote p,
.summernote iframe,
.summernote img ol,
.summernote li,
.summernote u,
.summernote b,
.summernote s,
.summernote div {
    height: auto !important;
    width: 100% !important;
    position: relative !important;
  }
  .ngxImageZoomContainer {
    width: 100% !important;
    height: auto !important;
  }
}
main {
  padding-top: 173px;
}

.container {
  width: 100%;
  padding: 0 5%;
  margin: 0 auto;
  max-width: 1600px;
}

.btn {
  position: relative;
  padding: 16px 32px;
  display: inline-block;
  text-align: center;
  border: 0;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 4px;
  text-transform: uppercase;
}
.btn-default {
  background: #532680;
  color: #ffffff;
}
.btn-default:hover {
  background: #94c13e;
}
.btn-outlined {
  background: transparent;
  color: #532680;
  border: 1px solid #532680;
}
.btn-outlined:hover {
  background: #532680;
  color: #ffffff;
}
.btn-small {
  font-size: 14px;
  padding: 12px 24px;
}
.btn-large {
  font-size: 20px;
  padding: 20px 50px;
}
.btn-block {
  width: 100%;
  display: block;
  text-align: center;
}
.btn:hover {
  text-decoration: none;
}

.title {
  margin-bottom: 70px;
  text-align: center;
}
.title h2 {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: #532680;
}
.title-link {
  display: inline-flex;
  align-items: center;
  color: #707070;
}
.title-link .fas {
  margin-left: 8px;
}

.form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.form-control {
  margin-bottom: 20px;
  flex: 0 0 100%;
  width: 100%;
}
.form-control.half {
  flex: 0 0 49%;
  width: 49%;
}
.form-control input {
  border-radius: 0px;
  height: 44px;
  background: #f2f2f2;
  width: 100%;
  border-radius: 0px;
  font-size: 15px;
  padding: 16px;
  border-radius: 12px;
}
.form-control textarea {
  border-radius: 0px;
  min-height: 180px;
  background: #f2f2f2;
  width: 100%;
  border-radius: 0px;
  font-size: 15px;
  padding: 16px;
  resize: none;
  border-radius: 12px;
}
.form-control__hint {
  font-size: 12px;
  display: block;
  margin-top: 5px;
  color: red;
}
.form-button {
  text-align: center;
  flex: 0 0 100%;
  width: 100%;
}

.category {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 1px solid #dadada;
}
.category-item {
  margin: 0 30px;
}
.category-item input[type=radio],
.category-item input[type=check] {
  display: none;
}
.category-item input[type=radio]:checked + label,
.category-item input[type=check]:checked + label {
  font-weight: 700;
  text-decoration: none;
  color: #532680;
}
.category-item input[type=radio]:checked + label::before,
.category-item input[type=check]:checked + label::before {
  opacity: 1;
  height: 4px;
  width: 90%;
}
.category-item label {
  font-size: 16px;
  position: relative;
  padding: 0 0 20px;
  display: inline-block;
}
.category-item label::before {
  content: "";
  background: #532680;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 30%;
  height: 0px;
  border-radius: 6px;
  opacity: 0;
  transform: translateX(-50%);
  transition: height 0.15s ease, bottom 0.15s ease, width 0.2s ease-in-out 0.15s, opacity 0.3s ease;
}
.category-item label:hover {
  text-decoration: none;
  color: #532680;
}
.category-item label:hover::before {
  opacity: 1;
  height: 4px;
  width: 90%;
}
.category-item:hover {
  cursor: pointer;
}

.box-service {
  flex: 0 0 31.33%;
  width: 31.33%;
  display: inline-block;
  position: relative;
  min-height: 240px;
  overflow: hidden;
}
.box-service__title {
  text-align: center;
  color: #532680;
  position: absolute;
  z-index: 2;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%, 0%);
  transition: all 200ms ease-in-out;
  font-size: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  opacity: 0;
}
.box-service img {
  object-fit: cover;
  width: 100%;
  height: 240px;
  transition: all 200ms ease-in-out;
  display: block;
}
.box-service::before {
  content: "";
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  transition: all 200ms ease-in-out;
  opacity: 0;
}
.box-service:hover {
  text-decoration: none;
}
.box-service:hover .box-service__title {
  bottom: 50%;
  opacity: 1;
  transform: translate(-50%, 50%);
}
.box-service:hover img {
  transform: scale(1.1);
}
.box-service:hover::before {
  opacity: 1;
}

.products-list {
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
}
.products-list__button {
  flex: 0 0 100%;
  width: 100%;
  text-align: center;
}
.products-item {
  flex: 0 0 calc(25% - 18px);
  width: calc(25% - 18px);
  border-radius: 12px;
  border: 1px solid #dadada;
  transition: all 300ms;
  margin-bottom: 40px;
  position: relative;
}
.products-item__image {
  padding: 36px 36px 24px;
  position: relative;
  overflow: hidden;
  display: block;
}
.products-item__image img {
  display: block;
  height: 240px;
  width: 100%;
  object-fit: contain;
}
.products-item__image:hover {
  cursor: pointer;
}
.products-item__content {
  text-align: center;
  padding: 0 36px 92px;
}
.products-item__title {
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.products-item__price {
  display: block;
  margin: 10px 0 6px;
  font-size: 30px;
  font-weight: 700;
  color: #532680;
}
.products-item__price small {
  font-size: 14px;
  text-decoration: line-through;
  color: #707070;
}
.products-item__description {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 1.4;
  color: #707070;
}
.products-item .btn {
  position: absolute;
  bottom: 24px;
  left: 36px;
  width: calc(100% - 72px);
}
.products-item:hover {
  box-shadow: 0 5px 10px rgba(36, 50, 56, 0.25);
}
.products-filter {
  background: #f2f2f2;
  padding: 16px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  margin-bottom: 30px;
}
.products-filter span {
  font-size: 15px;
  color: #707070;
}
.products-filter span b {
  color: #532680;
}
.products-filter__select {
  position: relative;
}
.products-filter__select label {
  font-size: 14px;
  color: #707070;
  margin-right: 5px;
}
.products-filter__select select {
  height: 44px;
  padding: 0 16px;
  background: transparent;
  border-radius: 0px;
  -webkit-appearance: none;
  appearance: none;
  font-size: 15px;
  border: 1px solid #532680;
  outline: 0;
  border-radius: 12px;
}
.products-filter__select::before {
  content: "\f0d7";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 16px;
  font-size: 14px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.blog-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.blog-item {
  width: 49%;
  flex: 0 0 49%;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.blog-item__image {
  min-height: 300px;
  height: 100%;
  width: 230px;
  flex: 0 0 230px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}
.blog-item__image img {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
}
.blog-item__content {
  padding-left: 40px;
  padding-top: 40px;
  padding-bottom: 30px;
}
.blog-item__title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-item__description {
  font-size: 14px;
  line-height: 1.5;
}
.blog-item .btn {
  margin-top: 50px;
}

.social-share__item {
  display: block;
  margin-bottom: 15px;
}
.social-share__item .fab,
.social-share__item .fas {
  line-height: 48px;
  color: #ffffff;
  font-size: 1.25em;
}
.social-share__link {
  display: inline-block;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  background: #94c13e;
  text-align: center;
}
.social-share__link:hover {
  transform: scale(1.1);
}
.social-share__link--facebook {
  background: #475a95;
}
.social-share__link--twitter {
  background: #659fef;
}
.social-share__link--linkedin {
  background: #4b77b2;
}
.social-share__link--mail {
  background: #848484;
}

.filter .ngx-slider .ngx-slider-bar {
  background: #d5d5d5;
  height: 5px;
}
.filter .ngx-slider .ngx-slider-bubble {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}
.filter .ngx-slider .ngx-slider-bubble.ngx-slider-model-value, .filter .ngx-slider .ngx-slider-bubble.ngx-slider-model-high {
  font-weight: 700;
  color: #532680;
  font-size: 20px;
}
.filter .ngx-slider .ngx-slider-pointer {
  background-color: #532680;
  width: 16px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
  outline: 0;
}
.filter .ngx-slider .ngx-slider-pointer.ngx-slider-active::after {
  background: #ffffff;
}
.filter .ngx-slider .ngx-slider-pointer::after {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.filter .ngx-slider .ngx-slider-selection {
  background: #532680;
}

@media screen and (max-width: 768px) {
  main {
    padding-top: 54px;
  }
  .title {
    margin-bottom: 40px;
  }
  .form-control.half {
    flex: 0 0 100%;
    width: 100%;
  }
  .box-service {
    flex: 0 0 100%;
    width: 100%;
    min-height: 120px;
    margin-bottom: 16px;
  }
  .box-service::before {
    opacity: 1;
  }
  .box-service__title {
    bottom: 50%;
    transform: translate(-50%, 50%);
  }
  .box-service img {
    height: 120px;
  }
  .category {
    flex-flow: column;
    align-items: center;
  }
  .category-item {
    padding: 10px 0;
  }
  .products-list {
    justify-content: space-between;
  }
  .products-filter {
    flex-flow: column;
    align-items: stretch;
  }
  .products-filter__select {
    margin-top: 20px;
    display: flex;
    flex-flow: row;
    align-items: center;
  }
  .products-filter__select label {
    flex-grow: 1;
    width: 100%;
  }
  .products-item {
    flex: 0 1 100%;
    width: 100%;
  }
  .products-item__image {
    min-height: 160px;
  }
  .products-item__image img {
    height: 160px;
  }
  .products-item:not(:nth-child(4n)) {
    margin-right: 0px;
  }
  .blog-list {
    flex-flow: column;
  }
  .blog-item {
    flex: 0 0 100%;
    width: 100%;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 40px;
  }
  .blog-item__image {
    min-height: 160px;
    flex: 0 0 100%;
    width: 100%;
    height: auto;
  }
  .blog-item__image img {
    height: 160px;
    width: 100%;
  }
  .blog-item__content {
    padding: 16px;
  }
  .blog-item__content .btn {
    display: block;
    width: 100%;
    text-align: center;
  }
}